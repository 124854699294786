import { UploadOutlined } from "@ant-design/icons";
import { DatePicker, Input, Form, Modal, Upload } from "antd";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import FormItem from "../../../../reusable/FormItem";
import { Toast } from "../../../../reusable/Toast";
import { setDone, setLoading } from "../../../../store/loading";
import axiosConfig from "../../../../utils/axiosConfig";
import { API_ENDPOINT } from "../../../../utils/config";
import { getErrorValue } from "../../../../utils/getErrors";
import numeral from "../../../../utils/numeral";

const initialValues = {
  transfer_time: "",
  image: null,
  catatan: "Penarikan saldo berhasil",
};

const validationSchema = (modal) => {
  if (!isEmpty(modal) && modal.log.type_withdraw === "manual") {
    return Yup.object().shape({
      transfer_time: Yup.string().required("Waktu Transfer wajib diisi"),
    });
  }
  return;
};

function AcceptModal({ modal, setModal, fetchRequestWithdraw }) {
  const [errorMessage, setErrorMessage] = useState({});
  const dispatch = useDispatch();

  const onSubmit = async (values) => {
    dispatch(setLoading());
    onClose();
    try {
      let formattedTime =
        !isEmpty(modal) && modal.log.type_withdraw === "manual"
          ? values.transfer_time.format("YYYY-MM-DD HH:mm")
          : "";
      const response = await axiosConfig.post(
        `${API_ENDPOINT}/withdraw/accept/${modal.log.id}`,
        {
          image: values.image,
          transfer_time: formattedTime,
          catatan: values.catatan,
        }
      );
      Toast.fire({
        icon: "success",
        title: "Berhasil Mengirimkan Kode Verifikasi. Harap cek email",
      });
      fetchRequestWithdraw({
        current: 1,
        pageSize: 10,
      });
      setErrorMessage({});
      setModal({
        type: "acceptVerified",
        log: modal.log,
      });
    } catch (error) {
      console.log(error);
      Toast.fire({
        icon: "error",
        title: error.response.data.info,
      });
      setErrorMessage(error.response.data.data.errors);
    } finally {
      dispatch(setDone());
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: validationSchema(modal),
  });

  const onUpload = (image) => {
    let reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = () => {
      formik.setFieldValue("image", reader.result);
    };
    reader.onerror = (err) => {
      console.log(err);
      Toast.fire({
        icon: "error",
        title: "Mengupload gambar gagal",
      });
    };
    return false;
  };
  const onClose = () => {
    formik.resetForm();
    setModal({});
  };

  const handleChangeDate = (date, dateString) => {
    formik.setFieldValue("transfer_time", date);
  };

  const handleChange = (value) => {
    formik.setFieldValue("catatan", value.target.value);
  };

  return (
    <Modal
      onCancel={onClose}
      open={modal.type === "accept"}
      title="Terima Ajuan Penarikan"
      onOk={formik.handleSubmit}
      okText="Terima"
      destroyOnClose
    >
      {!isEmpty(modal) && (
        <div className="grid grid-cols-2 gap-y-2 items-center">
          <p className="font-bold text-lg">Username:</p>
          <p className="text-lg justify-self-end">{modal.log.user.username}</p>
          <p className="font-bold text-lg">Bank:</p>
          <p className="text-lg justify-self-end">{modal.log.bank}</p>
          <p className="font-bold text-lg">Nama Pemilik Rekening:</p>
          <p className="text-lg justify-self-end">
            {modal.log.nama_pemilik_rekening}
          </p>
          <p className="font-bold text-lg">Nomor Rekening:</p>
          <p className="text-lg justify-self-end">{modal.log.nomor_rekening}</p>
          <p className="font-bold text-lg">Nominal:</p>
          <p className="text-lg justify-self-end">
            Rp. {numeral(modal.log.nominal).format("0,0")}
          </p>
          <p className="font-bold text-lg">Biaya Admin:</p>
          <p className="text-lg justify-self-end">
            Rp. {numeral(modal.log.admin_fee).format("0,0")}
          </p>
          <p className="font-bold text-lg">Total:</p>
          <p className="text-lg justify-self-end">
            Rp. {numeral(modal.log.total).format("0,0")}
          </p>
          <p className="font-bold text-lg">Tanggal Pengajuan:</p>
          <p className="text-lg justify-self-end">
            {modal.log.created_at}
          </p>
          <p className="font-bold text-lg">Status:</p>
          <p className="text-lg justify-self-end bg-blue-500 text-white px-4 font-bold rounded-lg">
            {modal.log.status}
          </p>
        </div>
      )}
      <div className="w-full border border-gray-200 my-4"></div>
      <Form layout="vertical">
        <h1 className="text-lg font-bold text-gray-600 my-2">
          {!isEmpty(modal) && modal.log.type_withdraw === "auto"
            ? "Isi Data"
            : "Isi Bukti Transfer"}
        </h1>
        {!isEmpty(modal) && modal.log.type_withdraw === "manual" && (
          <div>
            <FormItem
              label="Transfer Date"
              error={getErrorValue(
                formik.errors.transfer_time,
                errorMessage?.transfer_time
              )}
              touched={formik.touched.transfer_time}
            >
              <DatePicker
                className="w-full"
                showTime={{ defaultValue: moment("00:00", "HH:mm") }}
                format="YYYY-MM-DD HH:mm"
                onChange={handleChangeDate}
                onBlur={formik.handleBlur}
                name="transfer_time"
                size="large"
              />
            </FormItem>
            <FormItem label="Upload Bukti Transfer">
              <Upload
                action=""
                maxCount={1}
                beforeUpload={onUpload}
                type="drag"
                style={{ border: "0px" }}
                accept="image/*"
              >
                <div className="border border-gray-300 py-6 -my-4 w-full rounded-lg flex gap-12 items-center justify-center border-dashed hover:bg-gray-300 hover:bg-opacity-20">
                  <UploadOutlined style={{ fontSize: "24px" }} />
                  <div>
                    <p>Upload Bukti Transfer</p>
                    <p>Drag or Click</p>
                  </div>
                </div>
              </Upload>
            </FormItem>
          </div>
        )}
        <FormItem label="Catatan">
          <Input.TextArea
            defaultValue={formik.initialValues.catatan}
            placeholder="Masukan catatan untuk user"
            onChange={handleChange}
            onBlur={formik.handleBlur}
            name="catatan"
          />
        </FormItem>
        {!isEmpty(modal) && modal.log.type_withdraw === "manual" && (
          <p className="text-md text-red-600">
            Peringatan: Transaksi ini bertipe manual, silahkan lakukan transfer
            secara manual sebelum Anda klik Terima.
          </p>
        )}
        {!isEmpty(modal) && modal.log.type_withdraw === "auto" && (
          <p className="text-md text-red-600">
            Peringatan: Transaksi ini bertipe otomatis, harap pastikan dengan
            benar sebelum Anda klik Terima.
          </p>
        )}
      </Form>
    </Modal>
  );
}

export default AcceptModal;

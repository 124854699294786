import axios from 'axios';
import Cookies from 'js-cookie';

const axiosConfig = axios.create({});

axiosConfig.defaults.headers.common['Accept'] = 'application/json';
axiosConfig.defaults.headers.common['Content-Type'] = 'application/json';

const token = Cookies.get('token');
axiosConfig.defaults.headers.common['Authorization'] = `Bearer ${token}`;

export default axiosConfig;

import { createBrowserRouter } from 'react-router-dom';
import ProtectedRoute from '../inherit/ProtectedRoute';
import Dashboard from '../pages/dashboard/Dashboard';
import LoginPage from '../pages/Login';
import PaymentMethodDonation from '../pages/paymentMethod/donation/PaymentMethod';
import PaymentMethodEventAndOnlinestore from '../pages/paymentMethod/event-and-onlinestore/PaymentMethod';
import PaymentMethodSubscription from '../pages/paymentMethod/subscription/PaymentMethod';
import History from '../pages/request/history/History';
import RequestWithdraw from '../pages/request/withdrawal/RequestWithdraw';
import Settlement from '../pages/settlement/Settlement';
import Transaction from '../pages/transaction/Transaction';
import TransactionManual from '../pages/transaction/TransactionManual';
import Customer from '../pages/customer/data/Data';
import Data from '../pages/customer/data/Data';
import TransactionCustomer from '../pages/customer/transaction/TransactionCustomer';

export const router = createBrowserRouter([
	{
		path: '/login',
		element: <LoginPage />,
	},
	{
		element: <ProtectedRoute />,
		children: [
			{
				path: 'dashboard',
				element: <Dashboard />,
			},
			{
				path: 'automatic-transaction',
				element: <Transaction />,
			},
			{
				path: 'manual-transaction',
				element: <TransactionManual />,
			},
			{
				path: 'settlement',
				element: <Settlement />,
			},
			{
				path: 'payment-method',
				children: [
					{
						path: 'subscription',
						element: <PaymentMethodSubscription />,
					},
					{
						path: 'event-and-onlinestore',
						element: <PaymentMethodEventAndOnlinestore />,
					},
					{
						path: 'donation',
						element: <PaymentMethodDonation />,
					},
				],
			},
			{
				path: 'request',
				children: [
					{
						path: 'withdraw',
						element: <RequestWithdraw />,
					},
					{
						path: 'history',
						element: <History />,
					},
				],
			},
			{
				path: 'customer',
				children: [
					{
						path: 'data',
						element: <Data />,
					},
					{
						path: 'transaction',
						element: <TransactionCustomer />,
					},
				]
			},
		],
	},
]);

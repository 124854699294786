import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import { DatePicker, Input, Select, Table, Button } from "antd";
import { isEmpty, size } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import TotalSettlement from "../../../components/TotalSettlement";
import Layout from "../../../layout/Layout";
import { Toast } from "../../../reusable/Toast";
import axiosConfig from "../../../utils/axiosConfig";
import { API_ENDPOINT } from "../../../utils/config";
import numeral from "../../../utils/numeral";
import DetailModal from "./_partials/DetailModal";

const { Search } = Input;

const { Option } = Select;
const { RangePicker } = DatePicker;

const columns = (setModal) => {
  const onAcceptModal = (log) => {
    setModal({
      type: "accept",
      log,
    });
  };

  const onRejectModal = (log) => {
    setModal({
      type: "reject",
      log,
    });
  };
  return [
    {
      title: "No",
      render: (record, item, index) => index + 1,
      key: "no",
    },
    {
      title: "Invoice",
      dataIndex: "invoice",
      sorter: true,
      key: "invoice",
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      sorter: true,
      render: (record, item) => item.user.username,
    },
    {
      title: "Bank",
      dataIndex: "bank",
      sorter: true,
      key: "bank",
    },
    {
      title: "Nama Pemilik Rekening",
      dataIndex: "nama_pemilik_rekening",
      sorter: true,
      key: "nama_pemilik_rekening",
    },
    {
      title: "Nominal",
      dataIndex: "nominal",
      sorter: true,
      key: "nominal",
      render: (text, record) => (
        <>Rp. {numeral(record.nominal).format("0,0")}</>
      ),
    },
    {
      title: "Biaya Admin",
      dataIndex: "admin_fee",
      sorter: true,
      key: "admin_fee",
      render: (text, record) => (
        <>Rp. {numeral(record.admin_fee).format("0,0")}</>
      ),
    },
    {
      title: "Total",
      dataIndex: "total",
      sorter: true,
      key: "total",
      render: (text, record) => <>Rp. {numeral(record.total).format("0,0")}</>,
    },
    {
      title: "Tanggal Pengajuan",
      dataIndex: "created_at",
      sorter: true,
      key: "created_at",
    },
    {
      title: "status",
      dataIndex: "status",
      sorter: true,
      key: "status",
      render: (record) => {
        if (record === "unverified") {
          return (
            <div className="bg-yellow-500 border border-yellow-500 rounded-md text-white text-center inline px-2 py-1 capitalize font-bold">
              {record}
            </div>
          );
        } else if (record === "waiting") {
          return (
            <div className="bg-blue-500 border border-blue-500 rounded-md text-white text-center inline px-2 py-1 capitalize font-bold">
              {record}
            </div>
          );
        } else if (record === "rejected") {
          return (
            <div className="bg-red-500 border border-red-500 rounded-md text-white text-center inline px-2 py-1 capitalize font-bold">
              {record}
            </div>
          );
        } else if (record === "expired") {
          return (
            <div className="bg-gray-500 border border-gray-500 rounded-md text-white text-center inline px-2 py-1 capitalize font-bold">
              {record}
            </div>
          );
        } else {
          return (
            <div className="bg-green-500 border border-green-500 rounded-md text-white text-center inline px-2 py-1 capitalize font-bold">
              {record}
            </div>
          );
        }
      },
    },
    {
      title: "Action",
      render: (record, item) => (
        <button className="bg-white" onClick={() => setModal(item)}>
          <EyeOutlined style={{ fontSize: "20px" }} />
        </button>
      ),
    },
  ];
};

const initialPagination = {
  current: 1,
  pageSize: 10,
};

function History() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [withdraws, setWithdraws] = useState([]);
  const [modal, setModal] = useState({});
  const [search, setSearch] = useState("");
  const [paginationState, setPaginationState] = useState(initialPagination);

  const [date, setDate] = useState({
    date_from: moment(),
    date_to: moment(),
  });
  const onChangeDate = (date, dateStrings) => {
    setDate({
      date_from: date[0],
      date_to: date[1],
    });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (isEmpty(sorter)) {
      fetchRequestWithdraw(pagination, {});
    } else {
      fetchRequestWithdraw(pagination, {
        field: sorter.field,
        sort: sorter.order,
      });
    }
  };
  const onSearch = (value) => {
    setSearch(value);
  };
  const disabledDate = (current) => {
    return current && current > moment();
  };

  const exportHandle = async () => {
    setLoading(true);
    try {
      const response = await axiosConfig.get(
        `${API_ENDPOINT}/withdraw/export`,
        {
          params: {
            date_from: date.date_from.format("YYYY-MM-DD"),
            date_to: date.date_to.format("YYYY-MM-DD"),
          },
        }
      );
      const link = document.createElement("a");
      link.href = response.data.data;
      link.download = "Riwayat Penarikan Berbagilink.xlsx";
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error);
      Toast.fire({
        icon: "error",
        title:
          error.response.data?.info ??
          "Terjadi suatu error saat pengambilan data",
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchRequestWithdraw = async (pagination, sorter) => {
    setLoading(true);
    try {
      const response = await axiosConfig.get(`${API_ENDPOINT}/withdraw/list`, {
        params: {
          page: pagination.current,
          limit: pagination.pageSize,
          date_from: date.date_from.format("YYYY-MM-DD"),
          date_to: date.date_to.format("YYYY-MM-DD"),
          search,
          ...sorter,
        },
      });
      setData(response.data.data);
      setWithdraws(response.data.data.user_withdraws.data);
      setPaginationState({
        current: response.data.data.user_withdraws.current_page,
        pageSize: response.data.data.user_withdraws.per_page,
        total: response.data.data.user_withdraws.total,
      });
    } catch (error) {
      console.log(error);
      Toast.fire({
        icon: "error",
        title:
          error.response.data?.info ??
          "Terjadi suatu error saat pengambilan data",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRequestWithdraw(paginationState);
  }, [search, date]);

  return (
    <Layout>
      <DetailModal modal={modal} setModal={setModal} />
      <header className="py-3 px-6 border-t border-gray-300 bg-white w-full flex justify-between items-center">
        <div className="flex gap-3 items-center">
          <div>Dashboard</div>
          <div className="border-r h-4 border-gray-400"></div>
          <div className="text-gray-400">Berbagi.link</div>
        </div>
        <div className="flex gap-4 items-center">
          <Select defaultValue={"all"} className="w-40">
            <Option value="all">Semua</Option>
          </Select>
          <RangePicker
            onChange={onChangeDate}
            disabledDate={disabledDate}
            defaultValue={[moment(), moment()]}
            ranges={{
              "Hari Ini": [moment(), moment()],
              Kemarin: [moment().subtract(1, "days"), moment()],
              "Minggu ini": [moment().subtract(1, "week"), moment()],
              "Minggu lalu": [moment().subtract(2, "weeks"), moment()],
              "Bulan ini": [moment().subtract(1, "month"), moment()],
              "Bulan lalu": [
                moment().subtract(2, "months"),
                moment().subtract(1, "month"),
              ],
              "Tahun Ini": [moment().subtract(1, "year"), moment()],
            }}
            format="DD-MM-YYYY"
          />
        </div>
      </header>

      <div className="grid grid-cols-2 m-6 gap-6">
        <TotalSettlement date={date} search={search}/>
        <div className="bg-white grid col-span-2 rounded-md ">
          <div className="py-2 px-4 border-b-2 border-gray-300 font-bold text-gray-custom text-lg">
            Payment Reports
          </div>
          <div className="py-3 px-4 overflow-x-scroll">
            <div className="flex justify-between mt-3 mb-3">
              <div>
                <Button
                  type="primary"
                  shape="round"
                  size={"middle"}
                  onClick={exportHandle}
                >
                  Export
                </Button>
              </div>
              <div>
                <Search
                  placeholder="Masukkan pencarian"
                  className="max-w-xxs"
                  allowClear
                  enterButton="Cari"
                  onSearch={onSearch}
                  on
                />
              </div>
            </div>
            <Table
              columns={columns(setModal)}
              dataSource={withdraws}
              pagination={paginationState}
              loading={loading}
              onChange={handleTableChange}
              rowKey={(record) => record.id}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default History;

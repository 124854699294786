import {
  BarChartOutlined,
  DollarOutlined,
  FundFilled,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MoneyCollectOutlined,
  PieChartOutlined,
  PrinterOutlined,
  TransactionOutlined,
  UnorderedListOutlined,
  UserOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import { Dropdown, Layout as AntdLayout, Menu } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Toast } from "../reusable/Toast";
import { setDone, setLoading } from "../store/loading";
import { deleteUser } from "../store/user";
import axiosConfig from "../utils/axiosConfig";
import { API_ENDPOINT } from "../utils/config";

const { Sider, Header, Footer, Content } = AntdLayout;

const DropdownMenu = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = async () => {
    dispatch(setLoading());
    try {
      const response = await axiosConfig.post(`${API_ENDPOINT}/auth/logout`);
      Toast.fire({
        icon: "success",
        title: "Berhasil Logout",
      });
      dispatch(deleteUser());
      navigate("/login");
    } catch (error) {
      console.log(error);
      Toast.fire({
        icon: "error",
        title: error.response.data.info,
      });
    } finally {
      dispatch(setDone());
    }
  };
  return (
    <Menu
      className="p-4"
      items={[
        {
          key: "profile",
          disabled: true,
          label: (
            <div
              className="grid grid-cols-4 gap-4 mb-4 items-center cursor-default hover:bg-transparent"
              key={"Profile-name"}
            >
              <div className="w-16 h-16 bg-black rounded-full" />
              <div className="col-span-3 text-lg font-bold text-black">
                {data.name}
              </div>
            </div>
          ),
        },
        {
          key: "logout",
          danger: true,
          label: (
            <div onClick={handleLogout} className="text-lg px-4">
              Logout
            </div>
          ),
        },
      ]}
    />
  );
};

function Layout({ children }) {
  const [collapse, setCollapse] = useState(false);
  const { pathname } = useLocation();
  const routeName = pathname.split("/");
  const { data } = useSelector((state) => state.user);
  return (
    <AntdLayout className="min-h-screen">
      <Sider
        trigger={null}
        collapsible
        collapsed={collapse}
        className="overflow-auto sticky h-screen left-0 top-0 z-30"
        width={220}
      >
        {collapse ? (
          <div className="h-16" />
        ) : (
          <div className="flex justify-center items-center h-20">
            <img
              src="/images/berbagi-link-logo.png"
              className="w-36"
              alt="logo-berbagilink"
            />
          </div>
        )}
        <Menu
          theme="dark"
          mode="inline"
          defaultOpenKeys={[routeName[1]]}
          selectedKeys={[pathname]}
          items={[
            {
              key: "/dashboard",
              icon: <PieChartOutlined />,
              label: <Link to="/dashboard">Dashboard</Link>,
            },
            {
              key: "/withdraw",
              icon: <PrinterOutlined />,
              label: "Withdraw",
            },
            {
              key: "request",
              icon: <MoneyCollectOutlined />,
              label: "Penarikan Dana",
              children: [
                {
                  key: "/request/history",
                  icon: <BarChartOutlined />,
                  label: <Link to="/request/history">History</Link>,
                },
                {
                  key: "/request/withdraw",
                  icon: <MoneyCollectOutlined />,
                  label: <Link to="/request/withdraw">Request</Link>,
                },
              ],
            },
            {
              key: "/settlement",
              icon: <BarChartOutlined />,
              label: <Link to="/settlement">Settlement</Link>,
            },
            {
              key: "/automatic-transaction",
              icon: <TransactionOutlined />,
              label: (
                <Link to="/automatic-transaction">Automatic Transaction</Link>
              ),
            },
            {
              key: "/manual-transaction",
              icon: <WalletOutlined />,
              label: <Link to="/manual-transaction">Manual Transaction</Link>,
            },
            {
              key: "payment-method",
              icon: <DollarOutlined />,
              label: "Metode Pembayaran",
              children: [
                {
                  key: "/payment-method/subscription",
                  icon: <DollarOutlined />,
                  label: (
                    <Link to="/payment-method/subscription">
                      Berlangganan Berbagilink
                    </Link>
                  ),
                },
                {
                  key: "/payment-method/event-and-onlinestore",
                  icon: <DollarOutlined />,
                  label: (
                    <Link to="/payment-method/event-and-onlinestore">
                      Event &amp; Onlinestore
                    </Link>
                  ),
                },
                {
                  key: "/payment-method/donation",
                  icon: <DollarOutlined />,
                  label: <Link to="/payment-method/donation">Donasi</Link>,
                },
              ],
            },
            {
              key: "customer",
              icon: <UserOutlined />,
              label: "Customer",
              children: [
                {
                  key: "/customer/data",
                  icon: <UnorderedListOutlined />,
                  label: (
                    <Link to="/customer/data">
                      Data Customer
                    </Link>
                  ),
                },
                {
                  key: "/customer/transaction",
                  icon: <FundFilled />,
                  label: (
                    <Link to="/customer/transaction">
                      Transaction Customer
                    </Link>
                  ),
                },
              ],
            },
          ]}
        />
      </Sider>
      <AntdLayout>
        <Header className="w-full py-6 bg-white px-6 flex justify-between items-center sticky top-0 z-10">
          <div onClick={() => setCollapse(!collapse)}>
            {collapse ? (
              <MenuUnfoldOutlined className="text-xl" />
            ) : (
              <MenuFoldOutlined className="text-xl" />
            )}
          </div>
          <Dropdown overlay={<DropdownMenu data={data} />}>
            <div className="flex">
              <p>Hi, {data.name}</p>
            </div>
          </Dropdown>
        </Header>
        <Content className="">{children}</Content>
      </AntdLayout>
    </AntdLayout>
  );
}

export default Layout;

import { UploadOutlined } from "@ant-design/icons";
import { DatePicker, InputNumber, Form, Modal, Upload } from "antd";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import FormItem from "../../../../reusable/FormItem";
import { Toast } from "../../../../reusable/Toast";
import { setDone, setLoading } from "../../../../store/loading";
import axiosConfig from "../../../../utils/axiosConfig";
import { API_ENDPOINT } from "../../../../utils/config";
import { getErrorValue } from "../../../../utils/getErrors";
import numeral from "../../../../utils/numeral";

const initialValues = {
  verification_code: 0,
};

const validationSchema = (modal) => {
  return Yup.object().shape({
    verification_code: Yup.number()
      .typeError("Masukkan kode verifikasi yang valid")
      .required("Kode Verifikasi wajib diisi"),
  });
};

function AcceptVerifiedModal({ modal, setModal, fetchRequestWithdraw }) {
  const [errorMessage, setErrorMessage] = useState({});
  const dispatch = useDispatch();

  const onSubmit = async (values) => {
    console.log("hehe");
    dispatch(setLoading());
    try {
      const response = await axiosConfig.post(
        `${API_ENDPOINT}/withdraw/verified-accept/${modal.log.id}`,
        {
          verification_code: values.verification_code,
        }
      );
      Toast.fire({
        icon: "success",
        title: "Berhasil Menerima Pembayaran",
      });
      fetchRequestWithdraw({
        current: 1,
        pageSize: 10,
      });
      setErrorMessage({});
      onClose();
    } catch (error) {
      console.log(error);
      Toast.fire({
        icon: "error",
        title: error.response.data.info,
      });
      setErrorMessage(error.response.data.data.errors);
    } finally {
      dispatch(setDone());
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: validationSchema(modal),
  });
  const onClose = () => {
    formik.resetForm();
    setModal({});
  };

  const handleVerifikasiChange = (value) => {
    formik.setFieldValue("verification_code", value);
  };

  return (
    <Modal
      onCancel={onClose}
      open={modal.type === "acceptVerified"}
      title="Verifikasi Ajuan Penarikan"
      onOk={formik.handleSubmit}
      okText="Verifikasi"
      destroyOnClose
    >
      {!isEmpty(modal) && (
        <div className="grid grid-cols-2 gap-y-2 items-center">
          <p className="font-bold text-lg">Username:</p>
          <p className="text-lg justify-self-end">{modal.log.user.username}</p>
          <p className="font-bold text-lg">Bank:</p>
          <p className="text-lg justify-self-end">{modal.log.bank}</p>
          <p className="font-bold text-lg">Nama Pemilik Rekening:</p>
          <p className="text-lg justify-self-end">
            {modal.log.nama_pemilik_rekening}
          </p>
          <p className="font-bold text-lg">Nomor Rekening:</p>
          <p className="text-lg justify-self-end">{modal.log.nomor_rekening}</p>
          <p className="font-bold text-lg">Nominal:</p>
          <p className="text-lg justify-self-end">
            Rp. {numeral(modal.log.nominal).format("0,0")}
          </p>
          <p className="font-bold text-lg">Biaya Admin:</p>
          <p className="text-lg justify-self-end">
            Rp. {numeral(modal.log.admin_fee).format("0,0")}
          </p>
          <p className="font-bold text-lg">Total:</p>
          <p className="text-lg justify-self-end">
            Rp. {numeral(modal.log.total).format("0,0")}
          </p>
          <p className="font-bold text-lg">Tanggal Pengajuan:</p>
          <p className="text-lg justify-self-end">{modal.log.created_at}</p>
          <p className="font-bold text-lg">Status:</p>
          <p className="text-lg justify-self-end bg-blue-500 text-white px-4 font-bold rounded-lg">
            {modal.log.status}
          </p>
        </div>
      )}
      <div className="w-full border border-gray-200 my-4"></div>
      <Form layout="vertical">
        <h1 className="text-lg font-bold text-gray-600 my-2">
          Isi Kode Verifikasi
        </h1>
        <FormItem
          label="Kode Verifikasi"
          error={getErrorValue(
            formik.errors.verification_code,
            errorMessage?.verification_code
          )}
          touched={formik.touched.verification_code}
        >
          <InputNumber
            className="w-full"
            placeholder="Kode Verifikasi"
            size={"large"}
            onChange={handleVerifikasiChange}
            onBlur={formik.handleBlur}
            onError={formik.errors.verification_code}
            name="verification_code"
            type="number"
          />
        </FormItem>
      </Form>
    </Modal>
  );
}

export default AcceptVerifiedModal;

import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { isEmpty } from 'lodash';
import moment from 'moment';
import axiosConfig from '../utils/axiosConfig';

const initialState = {
	data: {},
	isLoggedIn: false,
};
const user = createSlice({
	name: 'user',
	initialState,
	reducers: {
		getUser: (state, action) => {
			const authData = JSON.parse(localStorage.getItem('auth_data'));
			state.data = authData;
			// jika saat di ambil data user kosong maka belum terlogin
			// dan jika ada datanya maka user sudah terlogin
			// state logged in ini dibutuhkan supaya ketika pages berubah, tidak selalu menjalankan
			// get user, karena jika menggunakan local storage
			// maka function ini akan terus terjalan
			if (!isEmpty(authData) && Cookies.get('token')) {
				state.isLoggedIn = true;
			} else {
				localStorage.removeItem('auth_data');
			}
			return state;
		},
		setUser: (state, action) => {
			localStorage.setItem('auth_data', JSON.stringify(action.payload.user));
			const expiredTime = new Date(moment().add(action.payload.expires_in, 's').toDate());
			Cookies.set('token', action.payload.access_token, {
				expires: expiredTime,
			});
			axiosConfig.defaults.headers.Authorization = `Bearer ${action.payload.access_token}`;
			state.data = action.payload.user;
			state.isLoggedIn = true;
			return state;
		},
		deleteUser: (state, action) => {
			state.data = {};
			state.isLoggedIn = false;
			localStorage.removeItem('auth_data');
			Cookies.remove('token');
			return state;
		},
	},
});

export const { getUser, setUser, deleteUser } = user.actions;
export default user.reducer;

import { DatePicker, Form, InputNumber, Modal } from 'antd';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import FormItem from '../../../reusable/FormItem';
import Input from '../../../reusable/Input';
import { Toast } from '../../../reusable/Toast';
import { setDone, setLoading } from '../../../store/loading';
import axiosConfig from '../../../utils/axiosConfig';
import { API_ENDPOINT } from '../../../utils/config';
import { getErrorValue } from '../../../utils/getErrors';
import numeral from '../../../utils/numeral';

const initialValues = {
	nominal: 0,
	settlement_date: moment().format('YYYY-MM-DD HH:mm'),
	note: '',
};

const validationSchema = Yup.object({
	nominal: Yup.number().min(100, 'nilai minimal adalah 100').required(),
	settlement_date: Yup.string().required(),
	note: Yup.string().nullable(),
});

function CreateModal({ openModal, setOpenModal, fetchSettlements }) {
	const dispatch = useDispatch();

	const handleClose = () => {
		setOpenModal(false);
	};
	const [errorMessage, setErrorMessage] = useState({});

	const onSubmit = async values => {
		dispatch(setLoading());
		try {
			const response = await axiosConfig.post(`${API_ENDPOINT}/settlements`, values);
			fetchSettlements();
			Toast.fire({
				icon: 'success',
				title: response.data.info,
			});
			handleClose();
		} catch (error) {
			console.log(error);
			Toast.fire({
				icon: 'error',
				title: error.response.data.info,
			});
			setErrorMessage(error.response.data.data.errors);
		} finally {
			dispatch(setDone());
		}
	};
	const {
		handleSubmit,
		errors,
		touched,
		handleBlur,
		handleChange,
		values,
		dirty,
		isValid,
		setFieldValue,
		setFieldTouched,
	} = useFormik({
		initialValues: initialValues,
		validationSchema,
		onSubmit,
		enableReinitialize: true,
	});

	const onDatePick = (value, dateString) => {
		setFieldValue('settlement_date', value.format('YYYY-MM-DD HH:mm'));
	};

	const customFormat = value => value.lang('id').format('DD MMMM YYYY, [pukul] HH:mm');

	return (
		<Modal
			open={openModal}
			title="Buat Settlement"
			onCancel={handleClose}
			footer={[
				<button onClick={handleClose} className="px-4 py-1 text-gray-500 bg-white border rounded-md">
					Close
				</button>,
				<button onClick={handleSubmit} className="px-4 py-1 text-white bg-primary border rounded-md ml-3">
					Save Changes
				</button>,
			]}
			destroyOnClose
		>
			<Form layout="vertical">
				<FormItem
					label="Nominal"
					error={getErrorValue(errors.nominal, errorMessage?.nominal)}
					touched={touched.nominal}
				>
					<InputNumber
						onChange={value => setFieldValue('nominal', value)}
						parser={value => value.replace(/\./g, '')}
						formatter={value => numeral(value).format('0,0')}
						onBlur={handleBlur}
						name="nominal"
						className="w-full"
						size="large"
						placeholder="1.000.000"
					/>
				</FormItem>
				<FormItem
					label="Tanggal Settlement"
					className="mb-4"
					error={getErrorValue(errors.settlement_date, errorMessage?.settlement_date)}
					touched={touched.settlement_date}
				>
					<DatePicker
						onChange={(date, dateString) => onDatePick(date, dateString)}
						format={customFormat}
						showTime={{ defaultValue: moment('00:00', 'HH:mm'), format: 'HH:mm' }}
						size="large"
						className="w-full"
						defaultValue={moment(values.settlement_date, 'YYYY-MM-DD HH:mm')}
						value={moment(values.settlement_date, 'YYYY-MM-DD HH:mm')}
					/>
				</FormItem>
				<FormItem label="Note" error={getErrorValue(errors.note, errorMessage?.note)} touched={touched.note}>
					<Input
						onChange={handleChange}
						onBlur={handleBlur}
						value={values.note}
						name="note"
						placeholder="Masukkan catatan"
						type="textarea"
					/>
				</FormItem>
			</Form>
		</Modal>
	);
}

export default CreateModal;

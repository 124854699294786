import { UploadOutlined } from "@ant-design/icons";
import { DatePicker, Input, Form, Modal, Upload } from "antd";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import FormItem from "../../../../reusable/FormItem";
import { Toast } from "../../../../reusable/Toast";
import { setDone, setLoading } from "../../../../store/loading";
import axiosConfig from "../../../../utils/axiosConfig";
import { API_ENDPOINT } from "../../../../utils/config";
import { getErrorValue } from "../../../../utils/getErrors";
import numeral from "../../../../utils/numeral";

const initialValues = {
  transfer_time: "",
  image: null,
  catatan: "Penarikan saldo berhasil",
};

const validationSchema = (modal) => {
  if (!isEmpty(modal) && modal.log.type_withdraw === "manual") {
    return Yup.object().shape({
      transfer_time: Yup.string().required("Waktu Transfer wajib diisi"),
    });
  }
  return;
};

function DetailModal({ modal, setModal, fetchCustomerDatatable }) {
  const [errorMessage, setErrorMessage] = useState({});
  const dispatch = useDispatch();
  const onSubmit = async (values) => {
    dispatch(setLoading());
    onClose();
    try {
      let formattedTime =
        !isEmpty(modal) && modal.log.type_withdraw === "manual"
          ? values.transfer_time.format("YYYY-MM-DD HH:mm")
          : "";
      const response = await axiosConfig.post(
        `${API_ENDPOINT}/withdraw/accept/${modal.log.id}`,
        {
          image: values.image,
          transfer_time: formattedTime,
          catatan: values.catatan,
        }
      );
      Toast.fire({
        icon: "success",
        title: "Berhasil Mengirimkan Kode Verifikasi. Harap cek email",
      });
      fetchCustomerDatatable({
        current: 1,
        pageSize: 10,
      });
      setErrorMessage({});
      setModal({
        type: "acceptVerified",
        log: modal.log,
      });
    } catch (error) {
      console.log(error);
      Toast.fire({
        icon: "error",
        title: error.response.data.info,
      });
      setErrorMessage(error.response.data.data.errors);
    } finally {
      dispatch(setDone());
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: validationSchema(modal),
  });

  const onUpload = (image) => {
    let reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = () => {
      formik.setFieldValue("image", reader.result);
    };
    reader.onerror = (err) => {
      console.log(err);
      Toast.fire({
        icon: "error",
        title: "Mengupload gambar gagal",
      });
    };
    return false;
  };
  const onClose = () => {
    formik.resetForm();
    setModal({});
  };

  const handleChangeDate = (date, dateString) => {
    formik.setFieldValue("transfer_time", date);
  };

  const handleChange = (value) => {
    formik.setFieldValue("catatan", value.target.value);
  };
  return (
    <Modal
      onOk={onClose}
      open={modal.type === "view"}
      title={"Detail User Berbagi.link | " + modal.log?.name}
      cancelButtonProps={{ style: { display: "none" } }}
      onCancel={onClose}
      destroyOnClose
      centered
      width={600}
    >
      {!isEmpty(modal) && (
        <div className="grid grid-cols-2 gap-3">
          <div className="">
            <div className="my-2">
              <p className="text-gray-500 font-semibold">Foto Profil</p>
              <img
                src={modal.log.profile_image ? modal.log.profile_image : "https://www.iconpacks.net/icons/2/free-user-icon-3296-thumb.png"}
                className="w-64 h-64 mt-1"
                alt=""
              />
            </div>
          </div>
          <div className="">
            <div className="my-2">
              <p className="text-gray-500 font-semibold">Username</p>
              <p className="mt-1">{modal.log.username}</p>
            </div>{" "}
            <div className="my-2">
              <p className="text-gray-500 font-semibold">Nama Akun</p>
              <p className="mt-1">{modal.log.name}</p>
            </div>{" "}
            <div className="my-2">
              <p className="text-gray-500 font-semibold">No. Handphone</p>
              <p className="mt-1">{modal.log.phone_number}</p>
            </div>
            <div className="my-2">
              <p className="text-gray-500 font-semibold">Email</p>
              <p className="mt-1">{modal.log.email}</p>
            </div>
            <div className="my-2">
              <p className="text-gray-500 font-semibold">Email</p>
              <p className="mt-1">{modal.log.email}</p>
            </div>
            <div className="my-2">
              <p className="text-gray-500 font-semibold">
                Status Pembayaran Instan
              </p>
              {modal.log.active_instant_payment ? (
                <div className="w-1/4 mt-1 px-2 py-1 bg-green-500 rounded-2xl">
                  <p className="font-bold text-white text-center">
                    Aktif
                  </p>
                </div>
              ) : null}
              {!modal.log.active_instant_payment ? (
                <div className="w-1/3 mt-1 px-2 py-1 bg-red-500 rounded-2xl">
                  <p className="font-bold text-white text-center">
                    Tidak Aktif
                  </p>
                </div>
              ) : null}
            </div>
            <div className="my-2">
              <p className="text-gray-500 font-semibold">Total Saldo</p>
              <p className="mt-1 font-bold text-teal-500">
                Rp. {numeral(modal.log.saldo).format("0,0")}
              </p>
            </div>
            <div className="my-2">
              <p className="text-gray-500 font-semibold">Total Saldo Ditarik</p>
              <p className="mt-1 font-bold text-red-500">
                Rp. {numeral(modal.log.pulled_saldo).format("0,0")}
              </p>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
}

export default DetailModal;

import { Form } from 'antd';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import FormItem from '../reusable/FormItem';
import Input from '../reusable/Input';
import SubmitButton from '../reusable/SubmitButton';
import { Toast } from '../reusable/Toast';
import { setDone, setLoading } from '../store/loading';
import { setUser } from '../store/user';
import axiosConfig from '../utils/axiosConfig';
import { API_ENDPOINT } from '../utils/config';
import { getErrorValue } from '../utils/getErrors';

const validationSchema = Yup.object().shape({
	email: Yup.string().email('Email tidak valid').required('Email wajib diisi'),
	password: Yup.string().required('Password wajib diisi'),
});

function LoginPage() {
	const initialState = {
		email: '',
		password: '',
	};
	const [input, setInput] = useState(initialState);
	const [errorMessage, setErrorMessage] = useState({});
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const onSubmit = async (values, { resetForm }) => {
		dispatch(setLoading());
		try {
			const response = await axiosConfig.post(`${API_ENDPOINT}/auth/login`, values);
			Toast.fire({
				icon: 'success',
				title: 'Berhasil Login',
			});
			dispatch(setUser(response.data.data));
			setErrorMessage({});
			navigate('/dashboard');
		} catch (error) {
			console.log(error);
			Toast.fire({
				icon: 'error',
				title: error.response.data.info,
			});
			setErrorMessage(error.response.data.data.errors);
		} finally {
			dispatch(setDone());
		}
	};
	return (
		<div className="h-screen bg-gray-50 grid grid-cols-2 w-full">
			<div className="flex flex-col gap-16 justify-center items-center">
				<div>
					<img src="/images/berbagi-link-logo.png" className="w-28 md:w-40 lg:w-64" alt="" />
				</div>
				<div className="text-center">
					<h1 className="text-2xl font-bold">Admin Bisa Berbagi Settlement</h1>
					<h2 className="text-lg mt-3">Login Admin bisa berbagi</h2>
				</div>
			</div>
			<div className="bg-white flex flex-col gap-6 justify-center items-center">
				<h1 className="text-center text-2xl font-semibold">Login to Your Account</h1>
				<Formik initialValues={input} onSubmit={onSubmit} validationSchema={validationSchema}>
					{({
						isSubmitting,
						handleSubmit,
						handleBlur,
						handleChange,
						errors,
						touched,
						values,
						setFieldValue,
						setFieldTouched,
						isValid,
						dirty,
					}) => (
						<Form layout="vertical" className="w-1/2" onFinish={handleSubmit}>
							{/* EMAIL */}
							<FormItem label="Email" error={getErrorValue(errors.email, errorMessage?.email)} touched={touched.email}>
								<Input
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.email}
									name="email"
									type="text"
									placeholder="Masukkan email anda"
								/>
							</FormItem>
							{/* Password */}
							<FormItem
								label="Password"
								error={getErrorValue(errors.password, errorMessage?.password)}
								touched={touched.password}
							>
								<Input
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.password}
									name="password"
									type="password"
									placeholder="Masukkan password anda"
								/>
							</FormItem>
							<SubmitButton dirty={dirty} isSubmitting={isSubmitting} isValid={isValid} className="py-2 my-10">
								Login
							</SubmitButton>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
}

export default LoginPage;

import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Toast } from "../reusable/Toast";
import axiosConfig from "../utils/axiosConfig";
import { API_ENDPOINT } from "../utils/config";
import numeral from "../utils/numeral";

const loadingIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

function TotalSettlement({ date, search }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const fetchBalance = async (pagination, sorter) => {
    setLoading(true);
    try {
      const response = await axiosConfig.get(
        `${API_ENDPOINT}/transaction/balance`,
        {
          params: {
            date_from: date.date_from.format("YYYY-MM-DD"),
            date_to: date.date_to.format("YYYY-MM-DD"),
            keyword: search,
          },
        }
      );
      setData(response.data.data);
    } catch (error) {
      console.log(error);
      Toast.fire({
        icon: "error",
        title: error.response.data?.info ?? "Terjadi Suatu Error",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBalance();
  }, [date, search]);

  return (
    <>
      <div className="col-span-2 bg-white grid grid-cols-3 rounded-md relative">
        {loading && (
          <div className="absolute top-0 left-0 w-full h-full bg-gray-custom bg-opacity-10 z-10 flex justify-center items-center">
            <Spin indicator={loadingIcon} />
          </div>
        )}
        <div className="col-span-3 py-3 px-4 border-b-2 border-gray-300 font-bold text-gray-custom">
          Total Balance Berbagi link Info Data pada{" "}
          {moment().diff(date.date_from, "days") > 0
            ? `${date.date_from.format("DD-MM-YYYY")} - ${date.date_to.format(
                "DD-MM-YYYY"
              )}`
            : "Hari Ini"}
        </div>
        <div className="grid grid-cols-2 px-4 py-8 gap-4">
          <div className="justify-self-start">
            <h3 className="font-bold text-sm">Nominal Transaksi</h3>
            <h3 className="text-xs mt-2">
              Total transaksi yang masuk ke saldo
            </h3>
          </div>
          <div className="font-bold text-2xl text-blue-600 justify-self-end">
            Rp.{" "}
            {data.balance?.balance_unsettled
              ? numeral(data.balance.balance_unsettled).format("0,0")
              : "0"}
          </div>
          <div className="col-span-2 border-2 border-blue-600 rounded-full self-end"></div>
        </div>
        <div className="grid grid-cols-2 px-4 py-8 gap-4 border-r-2 border-l-2 border-gray-300">
          <div className="justify-self-start">
            <h3 className="font-bold text-sm">Total Settlement</h3>
            <h3 className="text-xs mt-2">
              Dana yang sudah dikirim ke pengguna
            </h3>
          </div>
          <div className="font-bold text-2xl text-green-600 justify-self-end">
            Rp.{" "}
            {data.balance?.balance_settled
              ? numeral(data.balance.balance_settled).format("0,0")
              : "0"}
          </div>
          <div className="col-span-2 border-2 border-green-600 rounded-full self-end"></div>
        </div>
        <div className="grid grid-cols-2 px-4 py-8 gap-4">
          <div className="justify-self-start">
            <h3 className="font-bold text-sm">Total Saldo Keseluruhan</h3>
            <h3 className="text-xs mt-2">
              Total saldo yang masuk dan sudah disettle
            </h3>
          </div>
          <div className="font-bold text-2xl text-red-600 justify-self-end">
            Rp.{" "}
            {data.balance?.balance_total
              ? numeral(data.balance.balance_total).format("0,0")
              : "0"}
          </div>
          <div className="col-span-2 border-2 border-red-600 rounded-full self-end"></div>
        </div>
      </div>

      <div className="col-span-2 bg-white grid grid-cols-3 rounded-md relative">
        {loading && (
          <div className="absolute top-0 left-0 w-full h-full bg-gray-custom bg-opacity-10 z-10 flex justify-center items-center">
            <Spin indicator={loadingIcon} />
          </div>
        )}
        <div className="col-span-3 py-3 px-4 border-b-2 border-gray-300 font-bold text-gray-custom">
          Total Admin yang didapat Berbagi.link pada{" "}
          {moment().diff(date.date_from, "days") > 0
            ? `${date.date_from.format("DD-MM-YYYY")} - ${date.date_to.format(
                "DD-MM-YYYY"
              )}`
            : "Hari Ini"}
        </div>
        <div className="grid grid-cols-2 px-4 py-8 gap-4">
          <div className="justify-self-start">
            <h3 className="font-bold text-sm">Total Admin Channel</h3>
            <h3 className="text-xs mt-2">
              Total admin yang harus diserahkan ke bisabiller
            </h3>
          </div>
          <div className="font-bold text-2xl text-blue-600 justify-self-end">
            Rp.{" "}
            {data.balance?.admin_channel
              ? numeral(data.balance.admin_channel).format("0,0")
              : "0"}
          </div>
          <div className="col-span-2 border-2 border-blue-600 rounded-full self-end"></div>
        </div>
        <div className="grid grid-cols-2 px-4 py-8 gap-4 border-l-2 border-gray-300">
          <div className="justify-self-start">
            <h3 className="font-bold text-sm">Total Profit (Admin Platform)</h3>
            <h3 className="text-xs mt-2">
              Profit yang didapatkan Berbagi.link
            </h3>
          </div>
          <div className="font-bold text-2xl text-green-600 justify-self-end">
            Rp.{" "}
            {data.balance?.admin_platform
              ? numeral(data.balance.admin_platform).format("0,0")
              : "0"}
          </div>
          <div className="col-span-2 border-2 border-green-600 rounded-full self-end"></div>
        </div>
        <div className="grid grid-cols-2 px-4 py-8 gap-4 border-l-2 border-gray-300">
          <div className="justify-self-start">
            <h3 className="font-bold text-sm">Total Profit (Admin Penarikan)</h3>
            <h3 className="text-xs mt-2">
              Total Biaya Admin yang didapatkan dari Penarikan Dana
            </h3>
          </div>
          <div className="font-bold text-2xl text-red-600 justify-self-end">
            Rp.{" "}
            {data.balance?.admin_platform
              ? numeral(data.balance.admin_withdraw).format("0,0")
              : "0"}
          </div>
          <div className="col-span-2 border-2 border-red-600 rounded-full self-end"></div>
        </div>
      </div>
    </>
  );
}

export default TotalSettlement;

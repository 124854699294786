import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';
const loadingIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

function Loading() {
	return (
		<div className="fixed w-full h-screen bg-gray-custom bg-opacity-20 z-10 flex justify-center items-center">
			<Spin indicator={loadingIcon} />
		</div>
	);
}

export default Loading;
